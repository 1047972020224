import {useEffect} from 'react'
import {HeaderWrapper} from '../../../../_metronic/layout/components/header/user/HeaderWrapper'
import {CONSTANTS, PERSONALIZATION_FORM_CONSTANTS} from '../../../constants/AppConstants'
import PersonalizationFormInputs from './components/PersonalizationFormInputs'
import {usePersonalizationFormState} from './components/PersonalizationFormUtils'

const PersonalizationForm: React.FC = () => {
  // destructuring compare performance utils state
  const {scrollToForm, formik} = usePersonalizationFormState()

  useEffect(() => {
    const timerId = setTimeout(() => {
      scrollToForm()
    }, 5000)

    // Cleanup function to clear the timeout
    return () => clearTimeout(timerId)
  }, [])

  return (
    <>
      <HeaderWrapper />
      <div>
        <div className='sliding-div w-100 w-lg-50 d-flex flex-column justify-content-center text-center px-7'>
          <h1 className='mt-15 mb-0 text-black fw-600'>{PERSONALIZATION_FORM_CONSTANTS.HEADING}</h1>
          <br />
          <p className='text-black'>{PERSONALIZATION_FORM_CONSTANTS.PUNCHLINE}</p>
          <p className='text-black px-md-5'>{PERSONALIZATION_FORM_CONSTANTS.SUB_HEADING}</p>
          {/* <p className='text-black'>{PERSONALIZATION_FORM_CONSTANTS.OBJECTIVE}</p> */}
          <div
            className='chevron-down-container d-flex flex-column align-items-center mt-10'
            onClick={scrollToForm} // scroll to personalization form
          >
            <img
              alt='finger-print-img'
              className='finger-print-img'
              src={PERSONALIZATION_FORM_CONSTANTS.IMAGES.PIN_BASE_BLACK}
            />
            <i className='fa fa-chevron-down chevron-down-img' aria-hidden='true' />
          </div>
        </div>
        <div className='ask-chloee-form w-100 w-lg-50 d-flex justify-content-center'>
          <PersonalizationFormInputs />
        </div>
        <div className='chloee-right-section d-none d-lg-flex flex-column justify-content-center align-items-center w-50'>
          <img
            alt='chloee-img'
            className='chloee-logo-large'
            src={CONSTANTS.IMAGES.LOGO_SMALL_WhITE}
          />
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='primary-btn border-0 p-5'
            disabled={formik.isSubmitting || !formik.isValid}
            onClick={() => formik.handleSubmit()}
          >
            Get Started
          </button>
        </div>
      </div>
    </>
  )
}

export default PersonalizationForm

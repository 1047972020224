import Modal from 'react-bootstrap/Modal'
import {Button} from 'react-bootstrap'
import {FormEvent, useEffect, useState} from 'react'
import {addToWaitlist, getProgressionCount, getWaitListCount, getUserByEmail} from './core/_request'
import {toast} from 'react-toastify'
import useFocus from '../hooks/useFocus'
import {useJoinWaitlistContext} from '../user/context/JoinWaitlistContext'
import {Label} from 'recharts'
import {SOCIAL_ICONS} from '../constants/AppConstants'

interface JoinWaitlistModalProps {
  show: boolean
  onHide: () => void
}

const JoinWaitlistModal: React.FC<JoinWaitlistModalProps> = (props) => {
  const {setShowModal} = useJoinWaitlistContext()
  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)
  const notifySuccess = (msg: string) => toast.success(msg)
  let slug: string | null = ''
  const nameFocus = useFocus()
  const emailFocus = useFocus()

  const [waitListEmail, setWaitListEmail] = useState('')
  const [waitListName, setWaitListName] = useState('')
  const [step, setStep] = useState(1)

  const [isLoading, setIsLoading] = useState(false)
  const [positionNumber, setPositionNumber] = useState(0)
  const [linkToShare, setLinkToShare] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      if (localStorage.getItem('slug')) {
        slug = localStorage.getItem('slug')
        if (slug) {
          let user = await getUserByEmail(slug)
          setPositionNumber(user.data.position)
          setLinkToShare(slug)
        }
      }
      const waitlistStep = localStorage.getItem('waitlist-step')

      if (waitlistStep !== null && waitlistStep === '2' || waitlistStep === '3') {
        setStep(2)
      } else {
        setStep(1) // Set to default step or any other appropriate default value
      }
    }
    fetchData()
  }, [])
  // handle submit
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // add user to waitlist
    setIsLoading(true)

    try {
      const res = await addToWaitlist(waitListEmail)
      setIsLoading(false)
      notifySuccess('Thank You!')
      setPositionNumber(res.data.position)
      setStep(2)
      localStorage.setItem('waitlist-step', `2`)
      localStorage.setItem('slug', `${res.data.slug}`)
      setLinkToShare(res.data.slug) // Using template literals
      setWaitListEmail('')
      setWaitListName('')
    } catch (error: any) {
      if (error?.response?.data) {
        notifyError(error?.response?.data?.message)
      } else {
        notifyError('Error occurred during the request.')
      }
      setIsLoading(false)
    }
  }

  // const handleClose = () => {
  //   setShowModal(false)
  // }
  // waitlist UI toggler
  const handleWaitlistUI = () => {
    return (
      <>
        {step === 1 ? (
          <form onSubmit={handleSubmit}>
            <input
              ref={nameFocus.inputRef}
              type='text'
              className='form-control'
              placeholder='Enter your name'
              required
              value={waitListName}
              onChange={(e) => setWaitListName(e.target.value)}
            />

            <input
              ref={emailFocus.inputRef}
              type='email'
              className='form-control mt-5'
              placeholder='Enter your email'
              required
              value={waitListEmail}
              onChange={(e) => setWaitListEmail(e.target.value)}
            />

            <div className='d-flex justify-content-end align-items-end'>
              <Button type='submit' className='primary-btn border-0' disabled={isLoading}>
                {!isLoading && <span className='indicator-label'>SUBMIT</span>}
                {isLoading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </Button>
            </div>
          </form>
        ) : null}

        {step === 2 ? (
          <div className='queue-position-wrapper'>
            <h4 className='my-3 text-success'>You are In!😉</h4>
            <h4>Your number in queue</h4>
            <div className='queue-position-circle'>{positionNumber}</div>
            <div className='mt-3 mb-3'>Share the link to get bumped up in the queue.</div>
            <a className='mt-3 mb-3'>{`https://dev.chloee.xyz/preview/${linkToShare}`}</a>
            <div className='d-flex justify-content-between align-items-center mt mt-sm-0'>
              <a
                href={`mailto:?subject=Check out this site&body=I wanted to share this with you: https://dev.chloee.xyz/preview/${linkToShare}`}
                target='_blank'
                rel='noreferrer'
              >
                <img
                  alt='Email'
                  className='mx-2 cursor-pointer'
                  src={SOCIAL_ICONS.IMAGES.EMAIL_ICON}
                />
              </a>

              {/* Reddit - Replace 'YOUR_SUBREDDIT' with the actual subreddit you want to post to */}
              <a href={`https://www.whatsapp.com`} target='_blank' rel='noreferrer'>
                <img
                  alt='whatsapp'
                  className='mx-2 cursor-pointer'
                  src={SOCIAL_ICONS.IMAGES.WHATSAPP_ICON}
                />
              </a>

              {/* Discord - Requires setting up a redirect to your Discord server or a share invite link */}
              <a href={`https://www.instagram.com`}>
                <img
                  alt='instagram'
                  className='mx-2 cursor-pointer'
                  src={SOCIAL_ICONS.IMAGES.INSTAGRAM_ICON}
                />
              </a>

              {/* Snapchat - Use a direct link to your Snapchat profile or shareable content */}
              <a href={`https://www.tiktok.com`} target='_blank' rel='noreferrer'>
                <img
                  alt='tiktok'
                  className='mx-2 cursor-pointer'
                  src={SOCIAL_ICONS.IMAGES.TIKTOK_ICON}
                />
              </a>
            </div>
          </div>
        ) : null}
      </>
    )
  }
  return (
    <Modal
      {...props}
      size='sm'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      // onHide={handleClose}
      backdrop='static' // Prevents closing when clicking outside
      keyboard={false} // Prevents closing when pressing escape key
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter' className='heading sub-heading-x-small'>
          Join Waitlist
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{handleWaitlistUI()}</Modal.Body>
    </Modal>
  )
}

export default JoinWaitlistModal

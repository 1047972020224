import axios from 'axios'
const FETCH_ALL_USERS = 'users'
const UPDATE_USER_TOKENS = '/users/update-tokens'
const BLOCK_STATUS = '/users/block-status'

// fetching waitlist
export function fetchAllUsers() {
  return axios.get(FETCH_ALL_USERS)
}

// updating user tokens
export function updateUserTokens(
  id: number | undefined,
  daily_tokens_limit: number | undefined,
  available_tokens: number | undefined
) {
  console.log('', id, daily_tokens_limit, available_tokens)
  return axios.put(UPDATE_USER_TOKENS, {
    id: id,
    daily_tokens_limit,
    available_tokens,
  })
}

// block/unblock user
export function updateblockStatus(id: number, blockStatus: boolean) {
  return axios.post(BLOCK_STATUS, {
    id,
    blockStatus
  })
}